import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Destroy } from '@mds/angular-core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  template: '',
})
export abstract class BaseListComponent<TModel> extends Destroy implements OnInit {
  @HostBinding('class.mds-center-limit-width') private readonly isCentered = true;
  private readonly isLoadingSubject = new BehaviorSubject<boolean>(false);
  private readonly reloadSubject = new BehaviorSubject('initial');
  private readonly dataSubject = new BehaviorSubject<TModel[]>([]);

  readonly isLoading$ = this.isLoadingSubject.asObservable();
  readonly data$: Observable<TModel[]>;
  readonly searchForm: FormGroup;
  readonly hasWriteScopes$: Observable<boolean>;
  readonly hasDeleteScopes$: Observable<boolean>;
  readonly hasAuditScopes$: Observable<boolean>;

  constructor() {
    super();

    const formBuilder = inject(FormBuilder);
    const activatedRoute = inject(ActivatedRoute);
    this.searchForm = formBuilder.group({
      search: null,
    });

    this.data$ = this.dataSubject.asObservable();
    this.hasWriteScopes$ = activatedRoute.data.pipe(map((x) => x.hasWriteScopes as boolean));
    this.hasDeleteScopes$ = activatedRoute.data.pipe(map((x) => x.hasDeleteScopes as boolean));
    this.hasAuditScopes$ = activatedRoute.data.pipe(map((x) => x.hasAuditScopes as boolean));
  }

  ngOnInit(): void {
    this.reloadSubject
      .pipe(
        tap(() => this.dataSubject.next([])),
        tap(() => this.selectionReset()),
        tap(() => this.isLoadingSubject.next(true)),
        switchMap(() => this.loadData()),
        tap(() => this.isLoadingSubject.next(false))
      )
      .subscribe((models) => this.dataSubject.next(models));
  }

  reload(): void {
    this.reloadSubject.next('reload');
  }

  protected selectionReset(): void {
    return;
  }

  protected loadData(): Observable<TModel[]> {
    return of([]);
  }
}
